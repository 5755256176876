import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4e1143d0 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _79529f08 = () => interopDefault(import('../pages/agegate.vue' /* webpackChunkName: "pages/agegate" */))
const _7a68b230 = () => interopDefault(import('../pages/contato.vue' /* webpackChunkName: "pages/contato" */))
const _676a4082 = () => interopDefault(import('../pages/etapas.vue' /* webpackChunkName: "pages/etapas" */))
const _4b7f047d = () => interopDefault(import('../pages/extra-info.vue' /* webpackChunkName: "pages/extra-info" */))
const _37558e58 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _0a460424 = () => interopDefault(import('../pages/game.vue' /* webpackChunkName: "pages/game" */))
const _8319018c = () => interopDefault(import('../pages/ganhadores.vue' /* webpackChunkName: "pages/ganhadores" */))
const _0ab526d8 = () => interopDefault(import('../pages/kit.vue' /* webpackChunkName: "pages/kit" */))
const _18c9667e = () => interopDefault(import('../pages/perfil.vue' /* webpackChunkName: "pages/perfil" */))
const _c8894c34 = () => interopDefault(import('../pages/politica-de-privacidade.vue' /* webpackChunkName: "pages/politica-de-privacidade" */))
const _6832aa0a = () => interopDefault(import('../pages/regulamentos.vue' /* webpackChunkName: "pages/regulamentos" */))
const _4c2b333e = () => interopDefault(import('../pages/admin/actions/index.vue' /* webpackChunkName: "pages/admin/actions/index" */))
const _3bf501c7 = () => interopDefault(import('../pages/admin/calculation.vue' /* webpackChunkName: "pages/admin/calculation" */))
const _056d78fd = () => interopDefault(import('../pages/admin/commands/index.vue' /* webpackChunkName: "pages/admin/commands/index" */))
const _d92d2dcc = () => interopDefault(import('../pages/admin/cpf-block/index.vue' /* webpackChunkName: "pages/admin/cpf-block/index" */))
const _692b3192 = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _1714f29d = () => interopDefault(import('../pages/admin/dispatches/index.vue' /* webpackChunkName: "pages/admin/dispatches/index" */))
const _31e360c9 = () => interopDefault(import('../pages/admin/email-templates/index.vue' /* webpackChunkName: "pages/admin/email-templates/index" */))
const _257c3a00 = () => interopDefault(import('../pages/admin/exports/index.vue' /* webpackChunkName: "pages/admin/exports/index" */))
const _30ade5d2 = () => interopDefault(import('../pages/admin/faq/index.vue' /* webpackChunkName: "pages/admin/faq/index" */))
const _7aed4b81 = () => interopDefault(import('../pages/admin/indexes/index.vue' /* webpackChunkName: "pages/admin/indexes/index" */))
const _058c276c = () => interopDefault(import('../pages/admin/invalid-prizes/index.vue' /* webpackChunkName: "pages/admin/invalid-prizes/index" */))
const _6df5984a = () => interopDefault(import('../pages/admin/invoices/index.vue' /* webpackChunkName: "pages/admin/invoices/index" */))
const _506436ad = () => interopDefault(import('../pages/admin/ips/index.vue' /* webpackChunkName: "pages/admin/ips/index" */))
const _1e81d447 = () => interopDefault(import('../pages/admin/live-ranking.vue' /* webpackChunkName: "pages/admin/live-ranking" */))
const _02f63f05 = () => interopDefault(import('../pages/admin/permissions/index.vue' /* webpackChunkName: "pages/admin/permissions/index" */))
const _a2717f54 = () => interopDefault(import('../pages/admin/prizes/index.vue' /* webpackChunkName: "pages/admin/prizes/index" */))
const _96f9a51a = () => interopDefault(import('../pages/admin/qrcode-read.vue' /* webpackChunkName: "pages/admin/qrcode-read" */))
const _a32e73e4 = () => interopDefault(import('../pages/admin/questions/index.vue' /* webpackChunkName: "pages/admin/questions/index" */))
const _09c87992 = () => interopDefault(import('../pages/admin/ranking/index.vue' /* webpackChunkName: "pages/admin/ranking/index" */))
const _1178f74a = () => interopDefault(import('../pages/admin/registrations/index.vue' /* webpackChunkName: "pages/admin/registrations/index" */))
const _4c071b6c = () => interopDefault(import('../pages/admin/regulations/index.vue' /* webpackChunkName: "pages/admin/regulations/index" */))
const _12522ed6 = () => interopDefault(import('../pages/admin/statistics.vue' /* webpackChunkName: "pages/admin/statistics" */))
const _3b6704a8 = () => interopDefault(import('../pages/admin/tickets/index.vue' /* webpackChunkName: "pages/admin/tickets/index" */))
const _5bff2223 = () => interopDefault(import('../pages/admin/tickets-check/index.vue' /* webpackChunkName: "pages/admin/tickets-check/index" */))
const _c09157e2 = () => interopDefault(import('../pages/admin/tokens/index.vue' /* webpackChunkName: "pages/admin/tokens/index" */))
const _668f31df = () => interopDefault(import('../pages/admin/total-tickets/index.vue' /* webpackChunkName: "pages/admin/total-tickets/index" */))
const _175e8809 = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _bfef7cd6 = () => interopDefault(import('../pages/admin/winners/index.vue' /* webpackChunkName: "pages/admin/winners/index" */))
const _5925c2dc = () => interopDefault(import('../pages/admin/winners-draw/index.vue' /* webpackChunkName: "pages/admin/winners-draw/index" */))
const _6bd89728 = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _1fca2e18 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _09dc1b68 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _3a64a3e8 = () => interopDefault(import('../pages/admin/auth/forgot.vue' /* webpackChunkName: "pages/admin/auth/forgot" */))
const _650b7798 = () => interopDefault(import('../pages/admin/auth/login.vue' /* webpackChunkName: "pages/admin/auth/login" */))
const _3af5b68e = () => interopDefault(import('../pages/admin/auth/update.vue' /* webpackChunkName: "pages/admin/auth/update" */))
const _1984a87e = () => interopDefault(import('../pages/admin/dispatches/create.vue' /* webpackChunkName: "pages/admin/dispatches/create" */))
const _323d0315 = () => interopDefault(import('../pages/admin/email-templates/create.vue' /* webpackChunkName: "pages/admin/email-templates/create" */))
const _352ce087 = () => interopDefault(import('../pages/admin/faq/create.vue' /* webpackChunkName: "pages/admin/faq/create" */))
const _46644670 = () => interopDefault(import('../pages/admin/questions/create.vue' /* webpackChunkName: "pages/admin/questions/create" */))
const _e573bc98 = () => interopDefault(import('../pages/admin/regulations/create.vue' /* webpackChunkName: "pages/admin/regulations/create" */))
const _7ee7788f = () => interopDefault(import('../pages/admin/tokens/create.vue' /* webpackChunkName: "pages/admin/tokens/create" */))
const _07b27856 = () => interopDefault(import('../pages/admin/users/create.vue' /* webpackChunkName: "pages/admin/users/create" */))
const _7c2a0328 = () => interopDefault(import('../pages/admin/winners-draw/create.vue' /* webpackChunkName: "pages/admin/winners-draw/create" */))
const _5566b1e0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _c852fd82 = () => interopDefault(import('../pages/admin/auth/reset/_token.vue' /* webpackChunkName: "pages/admin/auth/reset/_token" */))
const _7a9de98e = () => interopDefault(import('../pages/admin/email-templates/edit/_id.vue' /* webpackChunkName: "pages/admin/email-templates/edit/_id" */))
const _fde79b00 = () => interopDefault(import('../pages/admin/faq/edit/_id.vue' /* webpackChunkName: "pages/admin/faq/edit/_id" */))
const _2201c229 = () => interopDefault(import('../pages/admin/questions/edit/_id.vue' /* webpackChunkName: "pages/admin/questions/edit/_id" */))
const _37d9dc6d = () => interopDefault(import('../pages/admin/regulations/edit/_id.vue' /* webpackChunkName: "pages/admin/regulations/edit/_id" */))
const _711a7a4e = () => interopDefault(import('../pages/admin/users/edit/_id.vue' /* webpackChunkName: "pages/admin/users/edit/_id" */))
const _528b29b6 = () => interopDefault(import('../pages/admin/winners-draw/edit/_id.vue' /* webpackChunkName: "pages/admin/winners-draw/edit/_id" */))
const _aa6e25b4 = () => interopDefault(import('../pages/admin/actions/_id.vue' /* webpackChunkName: "pages/admin/actions/_id" */))
const _2588f845 = () => interopDefault(import('../pages/admin/dispatches/_id.vue' /* webpackChunkName: "pages/admin/dispatches/_id" */))
const _269514d4 = () => interopDefault(import('../pages/admin/invalid-prizes/_id.vue' /* webpackChunkName: "pages/admin/invalid-prizes/_id" */))
const _37171dfa = () => interopDefault(import('../pages/admin/invoices/_id.vue' /* webpackChunkName: "pages/admin/invoices/_id" */))
const _10b9cb3e = () => interopDefault(import('../pages/admin/ips/_ip.vue' /* webpackChunkName: "pages/admin/ips/_ip" */))
const _56b5603e = () => interopDefault(import('../pages/admin/prizes/_id.vue' /* webpackChunkName: "pages/admin/prizes/_id" */))
const _570ce183 = () => interopDefault(import('../pages/admin/registrations/_id.vue' /* webpackChunkName: "pages/admin/registrations/_id" */))
const _608c1832 = () => interopDefault(import('../pages/admin/validation/_prize.vue' /* webpackChunkName: "pages/admin/validation/_prize" */))
const _6ed7047f = () => interopDefault(import('../pages/auth/reset/_token.vue' /* webpackChunkName: "pages/auth/reset/_token" */))
const _86c9a98c = () => interopDefault(import('../pages/unsubscribe/_id.vue' /* webpackChunkName: "pages/unsubscribe/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _4e1143d0,
    name: "admin"
  }, {
    path: "/agegate",
    component: _79529f08,
    name: "agegate"
  }, {
    path: "/contato",
    component: _7a68b230,
    name: "contato"
  }, {
    path: "/etapas",
    component: _676a4082,
    name: "etapas"
  }, {
    path: "/extra-info",
    component: _4b7f047d,
    name: "extra-info"
  }, {
    path: "/faq",
    component: _37558e58,
    name: "faq"
  }, {
    path: "/game",
    component: _0a460424,
    name: "game"
  }, {
    path: "/ganhadores",
    component: _8319018c,
    name: "ganhadores"
  }, {
    path: "/kit",
    component: _0ab526d8,
    name: "kit"
  }, {
    path: "/perfil",
    component: _18c9667e,
    name: "perfil"
  }, {
    path: "/politica-de-privacidade",
    component: _c8894c34,
    name: "politica-de-privacidade"
  }, {
    path: "/regulamentos",
    component: _6832aa0a,
    name: "regulamentos"
  }, {
    path: "/admin/actions",
    component: _4c2b333e,
    name: "admin-actions"
  }, {
    path: "/admin/calculation",
    component: _3bf501c7,
    name: "admin-calculation"
  }, {
    path: "/admin/commands",
    component: _056d78fd,
    name: "admin-commands"
  }, {
    path: "/admin/cpf-block",
    component: _d92d2dcc,
    name: "admin-cpf-block"
  }, {
    path: "/admin/dashboard",
    component: _692b3192,
    name: "admin-dashboard"
  }, {
    path: "/admin/dispatches",
    component: _1714f29d,
    name: "admin-dispatches"
  }, {
    path: "/admin/email-templates",
    component: _31e360c9,
    name: "admin-email-templates"
  }, {
    path: "/admin/exports",
    component: _257c3a00,
    name: "admin-exports"
  }, {
    path: "/admin/faq",
    component: _30ade5d2,
    name: "admin-faq"
  }, {
    path: "/admin/indexes",
    component: _7aed4b81,
    name: "admin-indexes"
  }, {
    path: "/admin/invalid-prizes",
    component: _058c276c,
    name: "admin-invalid-prizes"
  }, {
    path: "/admin/invoices",
    component: _6df5984a,
    name: "admin-invoices"
  }, {
    path: "/admin/ips",
    component: _506436ad,
    name: "admin-ips"
  }, {
    path: "/admin/live-ranking",
    component: _1e81d447,
    name: "admin-live-ranking"
  }, {
    path: "/admin/permissions",
    component: _02f63f05,
    name: "admin-permissions"
  }, {
    path: "/admin/prizes",
    component: _a2717f54,
    name: "admin-prizes"
  }, {
    path: "/admin/qrcode-read",
    component: _96f9a51a,
    name: "admin-qrcode-read"
  }, {
    path: "/admin/questions",
    component: _a32e73e4,
    name: "admin-questions"
  }, {
    path: "/admin/ranking",
    component: _09c87992,
    name: "admin-ranking"
  }, {
    path: "/admin/registrations",
    component: _1178f74a,
    name: "admin-registrations"
  }, {
    path: "/admin/regulations",
    component: _4c071b6c,
    name: "admin-regulations"
  }, {
    path: "/admin/statistics",
    component: _12522ed6,
    name: "admin-statistics"
  }, {
    path: "/admin/tickets",
    component: _3b6704a8,
    name: "admin-tickets"
  }, {
    path: "/admin/tickets-check",
    component: _5bff2223,
    name: "admin-tickets-check"
  }, {
    path: "/admin/tokens",
    component: _c09157e2,
    name: "admin-tokens"
  }, {
    path: "/admin/total-tickets",
    component: _668f31df,
    name: "admin-total-tickets"
  }, {
    path: "/admin/users",
    component: _175e8809,
    name: "admin-users"
  }, {
    path: "/admin/winners",
    component: _bfef7cd6,
    name: "admin-winners"
  }, {
    path: "/admin/winners-draw",
    component: _5925c2dc,
    name: "admin-winners-draw"
  }, {
    path: "/forgot",
    component: _6bd89728,
    name: "auth-forgot"
  }, {
    path: "/login",
    component: _1fca2e18,
    name: "auth-login"
  }, {
    path: "/register",
    component: _09dc1b68,
    name: "auth-register"
  }, {
    path: "/admin/forgot",
    component: _3a64a3e8,
    name: "admin-auth-forgot"
  }, {
    path: "/admin/login",
    component: _650b7798,
    name: "admin-auth-login"
  }, {
    path: "/admin/update",
    component: _3af5b68e,
    name: "admin-auth-update"
  }, {
    path: "/admin/dispatches/create",
    component: _1984a87e,
    name: "admin-dispatches-create"
  }, {
    path: "/admin/email-templates/create",
    component: _323d0315,
    name: "admin-email-templates-create"
  }, {
    path: "/admin/faq/create",
    component: _352ce087,
    name: "admin-faq-create"
  }, {
    path: "/admin/questions/create",
    component: _46644670,
    name: "admin-questions-create"
  }, {
    path: "/admin/regulations/create",
    component: _e573bc98,
    name: "admin-regulations-create"
  }, {
    path: "/admin/tokens/create",
    component: _7ee7788f,
    name: "admin-tokens-create"
  }, {
    path: "/admin/users/create",
    component: _07b27856,
    name: "admin-users-create"
  }, {
    path: "/admin/winners-draw/create",
    component: _7c2a0328,
    name: "admin-winners-draw-create"
  }, {
    path: "/",
    component: _5566b1e0,
    name: "index"
  }, {
    path: "/admin/reset/:token?",
    component: _c852fd82,
    name: "admin-auth-reset-token"
  }, {
    path: "/admin/email-templates/edit/:id",
    component: _7a9de98e,
    name: "admin-email-templates-edit-id"
  }, {
    path: "/admin/faq/edit/:id?",
    component: _fde79b00,
    name: "admin-faq-edit-id"
  }, {
    path: "/admin/questions/edit/:id?",
    component: _2201c229,
    name: "admin-questions-edit-id"
  }, {
    path: "/admin/regulations/edit/:id?",
    component: _37d9dc6d,
    name: "admin-regulations-edit-id"
  }, {
    path: "/admin/users/edit/:id?",
    component: _711a7a4e,
    name: "admin-users-edit-id"
  }, {
    path: "/admin/winners-draw/edit/:id",
    component: _528b29b6,
    name: "admin-winners-draw-edit-id"
  }, {
    path: "/admin/actions/:id",
    component: _aa6e25b4,
    name: "admin-actions-id"
  }, {
    path: "/admin/dispatches/:id",
    component: _2588f845,
    name: "admin-dispatches-id"
  }, {
    path: "/admin/invalid-prizes/:id?",
    component: _269514d4,
    name: "admin-invalid-prizes-id"
  }, {
    path: "/admin/invoices/:id",
    component: _37171dfa,
    name: "admin-invoices-id"
  }, {
    path: "/admin/ips/:ip",
    component: _10b9cb3e,
    name: "admin-ips-ip"
  }, {
    path: "/admin/prizes/:id",
    component: _56b5603e,
    name: "admin-prizes-id"
  }, {
    path: "/admin/registrations/:id",
    component: _570ce183,
    name: "admin-registrations-id"
  }, {
    path: "/admin/validation/:prize?",
    component: _608c1832,
    name: "admin-validation-prize"
  }, {
    path: "/reset/:token?",
    component: _6ed7047f,
    name: "auth-reset-token"
  }, {
    path: "/unsubscribe/:id?",
    component: _86c9a98c,
    name: "unsubscribe-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
