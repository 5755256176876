export default function ({ route, $axios, redirect, store, $config }) {
    return new Promise((resolve, reject) => {
        $axios.$get('/user-ranking', { disableToast: true }).then(user => {
            $config.isWinner = !!user
            $config.hasPicture = !!user?.picture
            $config.kit_retrieved = !!user?.kit_retrieved
        }).finally(() => {
            resolve()
        })
    })
}