const middleware = {}

middleware['agegate'] = require('../middleware/agegate.js')
middleware['agegate'] = middleware['agegate'].default || middleware['agegate']

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['checkPromoDates'] = require('../middleware/checkPromoDates.js')
middleware['checkPromoDates'] = middleware['checkPromoDates'].default || middleware['checkPromoDates']

middleware['gameStarted'] = require('../middleware/gameStarted.js')
middleware['gameStarted'] = middleware['gameStarted'].default || middleware['gameStarted']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['searchURLParams'] = require('../middleware/searchURLParams.js')
middleware['searchURLParams'] = middleware['searchURLParams'].default || middleware['searchURLParams']

middleware['strategies'] = require('../middleware/strategies.js')
middleware['strategies'] = middleware['strategies'].default || middleware['strategies']

middleware['winner'] = require('../middleware/winner.js')
middleware['winner'] = middleware['winner'].default || middleware['winner']

export default middleware
