<template>
  <v-main>
    <v-container class="py-15 fill-height d-flex flex-column align-center justify-center">
      <h1 class="text-h4 font-weight-light" v-text="error.statusCode" />
      <h1 class="text-h4 font-weight-light mt-1 mb-3" v-text="error.statusCode === 404 ? $t('ERROR_PAGE.404') :error.message" />
      <action-button
        v-if="$config.dev"
        color="daccent"
        :tooltip="{ top: 'Reload' }"
        :icon="$icons.reload"
        @click="$router.go()"
      />
      <div class="d-flex flex-wrap my-4">
        <v-btn v-if="$config.dev" color="wmain" outlined class="mr-2" @click="$router.go(-1)">
          <v-icon left v-text="$icons.left" /> {{ $t('ERROR_PAGE.BACK') }}
        </v-btn>
        <v-btn :large="!$config.dev" :class="{'text-h4': !$config.dev}" outlined dark color="wmain" @click="goHome">
          <span :class="{'tw-text-lg font-weight-regular': !$config.dev}">
            {{ $t('ERROR_PAGE.HOME') }}
          </span>
        </v-btn>
      </div>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'Error',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  layout ({ route }) {
    return route.path.includes('/admin') ? 'admin-dash' : 'default'
  },
  head () {
    return { title: 'Error ' + this.error.statusCode }
  },
  computed: {
    homePage () {
      return this.$route.path.includes('/admin') ? '/admin' : '/'
    }
  },
  methods: {
    goHome () {
      if (this.$route.path === '/') {
        this.$router.go()
      } else {
        this.$router.push(this.homePage)
      }
    }
  }
}
</script>
