<template>
	<v-app id="default-layout" light>
		<toolbar v-if="!$route.path.includes('game')" />
		<nuxt style="flex: 1; width: 100%;" />
		<NetflixFooter v-if="!$route.path.includes('game')"/>
		<Footer v-if="!$route.path.includes('game') && !$vuetify.breakpoint.mdAndUp"/>
		<!-- <cookie-banner /> -->
	</v-app>
</template>

<script>
export default {
	name: 'DefaultLayout',
	components: { 
		Toolbar: () => import('~/components/website/Toolbar'), 
		CookieBanner: () => import('~/components/website/CookieBanner'), 
		NetflixFooter: () => import("~/components/website/NetflixFooter"), 
		Footer: () => import("~/components/website/Footer"), 
	},
	mounted(){
		this.$vuetify.theme.dark = true
	}
}
</script>

<style lang="scss" src="~/assets/css/website/index.scss" />
